import React from "react"
import Seo from "../components/seo"
import "../components/layout.css";

const NotFoundPage = () => (
  <div className="container">
    <div className="_404">
      <Seo title="404 Станица не найдена" />
      <h1><span role="img" aria-label="">🤖</span>К сожалению, у нас нет такой страницы!</h1>
      <p className="center">Вернуться на <a href="/">главную станицу</a></p>
    </div>
  </div>
)

export default NotFoundPage
